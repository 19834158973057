<template>
  <!--涉及到多级路由参数-->
  <div class="page">

    <!--居中对齐-->
    <div class="notice-wrapper" v-show="ui.noticeBarStatus">
      <div class="center">
        <el-button
            size="small"
            :plain="false"
            type="primary"
            style="opacity: 0.5;display: block;width: 100%;margin-top: 5px;white-space: nowrap;"
            @click="close()">
          <i class="fa fa-warning i-gap"></i>
          请点击 " 对话框 " 右上角 <i class="fa fa-close"></i> 按钮，以
          <span style="color:red">" 正常关闭 "</span> 弹窗！
        </el-button>
      </div>
    </div>

    <!--表单滚动区域-->
    <div class="table-wrapper">

      <el-table
          ref="multipleTable"
          @row-dblclick="rowDblclick"
          :data="tableData"
          :height="table.height"
          :row-key="table.key"
          :header-cell-style="{fontSize: '15px',fontWeight: '100',backgroundColor:'#FAFAFA',height: '48px'}"
          style="width: 100%"
          stripe
          border>

        <el-table-column
            type="index"
            align="center"
            label-class-name="row-sn"
            width="50">
        </el-table-column>

        <el-table-column
            prop="title"
            label="服务名称"
            sortable>
          <template slot-scope="scope">

            <i :class="scope.row['prefix']" size="mini" style="color: #409EFF;margin-right: 10px"></i>

            {{scope.row['title']}}
          </template>
        </el-table-column>

        <el-table-column
            label="操作"
            label-class-name="row-oper"
            width="140"
            align="center"
            fixed="right">
          <template slot-scope="scope">

            <el-button
                class="oper-danger el-icon-delete"
                size="mini"
                plain
                type="danger"
                style="margin-right: 5px;"
                @click="deleteFavorites(scope.row)">
              删除
            </el-button>

            <el-button
                class="oper-danger el-icon-d-arrow-right"
                size="mini"
                plain
                type="danger"
                @click="rowDblclick(scope.row)">
            </el-button>

          </template>
        </el-table-column>

      </el-table>

    </div>


  </div>
</template>

<script>

export default {
  name: "showFavoritesMain",
  data() {
    return {

      // 界面参数
      ui: {
        noticeBarStatus: true,
      },

      // 表格
      table: {
        height: 0,
        key: "true",
      },

      // 输入参数
      caption: {
        title: '编辑',
      },

      // 表单数据
      form: {},

      // 收藏
      favorites: [],

      // 数据
      tableData: [],

    }
  },
  created() {

    // 初始化
    this.initParm();
    this.initCaption();

  },
  mounted() {

    // 检测窗体变化
    window.onresize = () => {
      return (() => {
        this.setResize();
      })()
    }

  },
  beforeUpdate() {

  },
  updated() {

  },
  activated() {

  },
  beforeDestroy() {

  },
  methods: {
    // 初始化参数
    initParm() {

      // 输入参数
      let e = this.$route.query;

      // 参数到模型
      for (let key in e) {
        this.caption[key] = e[key];
      }

      // 标题-动作形式
      this.setResize();

    },

    // 初始化数据格式
    initCaption() {

      this.tableData = window.parent.getFavorites();
    },

    // 初始化数据
    initData() {

    },

    // 单击行
    rowDblclick(row, column, event) {
      window.parent.showTagsByFavorites(row);
      this.close();
    },

    // 删除收藏
    deleteFavorites(row){

      let message = row['title']+"，删除成功！";
      let localStorage = window.localStorage;
      if (localStorage) {

        let localArr=[];
        let deleteIndex;
        for(let index in this.tableData){
          let entity = this.tableData[index];
          if(row["id"]==entity["id"]){
            deleteIndex = index;
          }else{
            localArr.push(entity["id"]);
          }
        }

        this.tableData.splice(deleteIndex);
        localStorage.setItem("favorites", JSON.stringify(localArr));

        this.$forceUpdate();

      } else {
        message = '不支持本地存储！';
      }

      this.$message({
        type: 'warning',
        message: message
      });

    },

    // 检测窗体变化
    setResize() {
      this.$nextTick(() => {
        this.table.height = document.documentElement.clientHeight - 70;
      });
    },

    // 关闭
    close() {
      window.parent.closeDialog();
    },
  },
}
</script>

<style lang="scss">

/*--基础+加载动画--*/
html, body, #app, .page {

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {

  animation: page-fade-in 1s forwards;
  background: url(../../static/images/bodyback.jpg);
}

@keyframes page-fade-in {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

/*--滚动条样式--*/
#app .el-table__body-wrapper::-webkit-scrollbar {
  border-radius: 10px;
  background-color: #F5F5F5;
}

#app .el-table__body-wrapper::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

#app .el-table__body-wrapper::-webkit-scrollbar-thumb {
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, .3);
  background-color: rgba(153, 153, 153, 0.05);
  background-image: url(../../static/images/icon.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
}

/*--通知性质栏--*/
.notice-wrapper {

  margin: 10px;
  margin-top: 10px;
  padding: 0px 5px;
  height: 42px;
  line-height: 40px;

  background: #FFF;
  border-radius: 6px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);

}

/*--小按钮--*/
.navbar-bars {

  display: block;

  .el-button {
    padding: 7px 8px;
    margin-left: 5px;
    margin-right: 0px;
    /*background-image: linear-gradient(0deg, #4094ff 5%, #F4F4F4 10%);*/
    color: #909399;
    border-radius: 10px;
  }
}

/*--表格--*/
.table-wrapper {

  margin: 0px 10px;
  margin-top: 10px;

  background: #FFF;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: auto;

  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);

  .el-table--border, .el-table--group {
    border: 0px;
  }

  .el-table thead .cell {
    white-space: nowrap;
    word-break: break-all;
  }

  .el-table thead .el-table__cell {
    padding: 0px !important;
    max-height: 45px !important;
    height: 45px !important;
  }

  .el-table tbody .el-table__cell {

    opacity: 0.95 !important;
    color: #676a6c !important;
    padding: 8px 0px !important;

    min-height: 45px !important;
    height: 45px !important;
  }

  .el-table {
    background: url(../../static/images/bg.jpg);
    background-repeat: no-repeat;
    background-position: right bottom;
  }

  /*--主键排序--*/
  .el-table th.row-sn:nth-child(1) > div:before {
    content: "\f292";
    font-weight: 100;
    font: normal normal normal 12px/1 FontAwesome;
    padding: 3px 5px;
    border-radius: 5px;
  }

  .oper-danger {
    background-image: linear-gradient(to top, #C71A1A 5%, #FFF2EA 10%);
    padding: 7px;
    margin-left: 0px;
    margin-right: 0px;
  }

}

/*--日期--*/
.datetime {
  color: #909399;
  font-family: "Helvetica Neue", "open sans", Helvetica, Arial, sans-serif;

  i {
    color: #c7c7c7;
  }
}

/*--标签--*/
.label {
  background-color: rgb(160, 207, 255);
  border-radius: 10px;
  color: rgb(255, 255, 255);
  border: 0px;
  height: 22px;
  line-height: 22px;
  padding: 0px 10px;
}

/*--alert弹框--*/
.el-message-box {
  max-width: 80%;
}

.i-gap {
  margin-right: 5px;
}

@media (max-width: 768px) {

  .el-form-item {
    float: none !important;
    width: auto !important;
  }
  .place-holder {
    display: none;
  }

}

</style>